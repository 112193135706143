<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGardenPduService.prepare_m_e_report') }} {{ $t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <!-- fiscal year -->
          <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-group
                label-for="fiscal_year_id"
                >
                  <template v-slot:label>
                    {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    plain
                    id="clone_info_id"
                    :options="fiscalYearList"
                    v-model="search.fiscal_year_id"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
          </b-col>
          <!-- from date -->
          <b-col xs="12" sm="12" md="3">
              <ValidationProvider name="From Date" vid="from_date" rules="required">
                <b-form-group
                    label-for="from_date"
                    slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{$t('globalTrans.from_date')}}
                  </template>
                  <date-picker
                    id="from_date"
                    v-model="search.from_date"
                    class="form-control"
                    :placeholder="$t('globalTrans.select_date')"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :class="errors[0] ? 'is-invalid' : ''"
                  >
                  </date-picker>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
          </b-col>
          <!-- to date -->
          <b-col xs="12" sm="12" md="3">
              <ValidationProvider name="To Date" vid="to_date" rules="required">
                <b-form-group
                    label-for="to_date"
                    slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{$t('globalTrans.to_date')}}
                  </template>
                  <date-picker
                    id="to_date"
                    v-model="search.to_date"
                    class="form-control"
                    :placeholder="$t('globalTrans.select_date')"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :class="errors[0] ? 'is-invalid' : ''"
                  >
                  </date-picker>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
          </b-col>
          <b-col sm="3">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenPduService.prepare_m_e_report') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col sm="12">
              <div class="quick-filter-wrapper justify-content-end">
                <div class="quick-filter-right">
                  <b-form-group
                    :label="$t('menu.perpage')"
                    label-for="per-page-select"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="search.limit"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="table-responsive">
              <b-table-simple hover small bordered responsive>
                <b-thead>
                  <b-tr>
                    <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                    <b-th>{{ $t('teaGardenBtriService.schedule_id') }}</b-th>
                    <b-th>{{ $t('teaGardenBtriService.schedule_name') }}</b-th>
                    <b-th>{{ $t('globalTrans.from_date') }}</b-th>
                    <b-th>{{ $t('globalTrans.to_date') }}</b-th>
                    <b-th>{{ $t('globalTrans.status') }}</b-th>
                    <b-th>{{ $t('globalTrans.action') }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <template v-if="listData.length">
                    <b-tr v-for="(item, index) in listData" :key="index" :class="{ 'available-schedule': availableSchedule(item.to_date) }">
                      <b-td>{{ $n(item.serial + pagination.slOffset) }}</b-td>
                      <b-td>{{ item.application_no }}</b-td>
                      <b-td>{{ currentLocal === 'bn' ? item.schedule_name_bn : item.schedule_name_en }}</b-td>
                      <b-td><span v-if="item.from_date">{{item.from_date | dateFormat}}</span></b-td>
                      <b-td><span v-if="item.to_date">{{item.to_date | dateFormat}}</span></b-td>
                      <b-td>{{ getStatusName(item.status) }}</b-td>
                      <b-td>
                        <router-link class="moc-action-btn moc-view-btn" :to="{ name: 'tea_garden_service.pdu.prepare_garden_list', params: { id: item.id }}" :title="$t('globalTrans.view') + ' ' + $t('cip.application_list')">
                        <i class="ri-eye-line"></i>
                      </router-link>
                      </b-td>
                    </b-tr>
                  </template>
                  <template v-else>
                    <b-tr>
                      <b-td colspan="7" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                    </b-tr>
                  </template>
                </b-tbody>
              </b-table-simple>
              <div class="pagination-wrapper mt-3">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
   <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <EditForm :items="items" :key="editItemId"/>
    </b-modal>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { prepareMEreportList } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/route-based-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        search: {
          fiscal_year_id: 0,
          limit: 10
        },
      items: [],
      viewitemId: 0,
      prepareItemId: '',
      actions: {
        edit: true,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    currentLocal () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions.map(item => {
        const customItem = {
          text: this.currentLocal === 'en' ? item.text : item.text_bn
        }
        return Object.assign({}, item, customItem)
      })
    },
    formTitle () {
      return this.$t('teaGardenBtriService.prepare_schedule_title') + ' ' + this.$t('globalTrans.modify')
    },
    statusList () {
      const arr = [
        { value: 1, text: this.$t('globalTrans.draft'), text_en: this.$t('globalTrans.draft', 'en'), text_bn: this.$t('globalTrans.draft', 'bn') },
        { value: 2, text: this.$t('globalTrans.pending'), text_en: this.$t('globalTrans.pending', 'en'), text_bn: this.$t('globalTrans.pending', 'bn') },
        { value: 4, text: this.$t('teaGardenPduService.assigned'), text_en: this.$t('teaGardenPduService.assigned', 'en'), text_bn: this.$t('teaGardenPduService.assigned', 'bn') }
      ]
      return arr
    }
  },
  methods: {
    editMethodInParent (item, type) {
      this.items = item
    },
    details (item) {
      this.items = item
    },
    searchData () {
      this.loadData()
    },
    getFiscalYear (yearId) {
      const cateObj = this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1).find(item => item.value === yearId)
      if (cateObj !== undefined) {
        if (this.$i18n.locale === 'bn') {
          return cateObj.text_bn
        } else {
          return cateObj.text_en
        }
      }
    },
    getStatusName (status) {
      const obj = this.statusList.find(item => item.value === parseInt(status))
      return this.currentLocal === 'en' ? obj?.text_en : obj?.text_bn
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, prepareMEreportList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          return Object.assign({}, item, { serial: index })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    availableSchedule (endDate) {
      const currentDate = new Date()
      const endDateTime = new Date(endDate)
      return endDateTime >= currentDate
    }
  }
}
</script>
<style scoped>
  .available-schedule {
    background-color:#cfefcb
  }
</style>
